<template>
  <div>
    <OrderList
      v-model="items"
      @reorder="onReorder"
      dataKey="id"
      listStyle="height:auto"
    >
      <template #header>
        <Button
          class="p-button-rounded"
          icon="pi pi-plus-circle"
          style="margin-right: 10px; margin-bottom: -5px"
          @click="onAddClick"
        />

        Carousel with {{ this.items.length }} slides
      </template>
      <template #item="slotProps">
        <div class="orderlist-item">
          <img
            @click="editImage(slotProps.item)"
            :src="'/api/download/' + slotProps.item.imageId + '?thumbnail=true'"
          />
          <div class="item-detail">
            <span>{{ slotProps.item.text }}</span>
          </div>
          <div class="item-action">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success p-mr-2"
              style="margin-right: 10px"
              @click="editText(slotProps.item)"
            />
          </div>
          <div class="item-action">
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger"
              @click="onDelete(slotProps.item)"
            />
          </div>
        </div>
      </template>
    </OrderList>
    <ModalBrowser modalId="carouselImagePicker" ref="modalBrowser" />
  </div>
</template>

<script>
import ModalBrowser from '@/components/modals/ModalBrowser.vue'
import Swal from 'sweetalert2'
export default {
  name: 'CarouselForm',
  components: { ModalBrowser },
  data() {
    return {
      display: false,
      items: [],
    }
  },
  mounted() {
    this.$refs.modalBrowser.fileType = 'images'
    this.getItems()
  },
  computed: {
    languages() {
      return this.$store.getters.komsol_langs
    },
  },
  methods: {
    async getItems() {
      const res = await this.$axios.get('komsol/carousels')
      this.items = res.data
    },
    async onReorder(event) {
      //Reorder items
      for (let i = 0; i < this.items.length; i++) {
        this.items[i].order = i
      }

      for (const item of this.items) {
        const res = await this.$axios.put('komsol/carousels/' + item.id, {
          order: item.order,
        })
      }
    },

    async onAddClick() {
      const length = this.items.length
      let newOrder = 0
      if (length > 0) {
        newOrder = this.items[length - 1]?.order + 1
      }
      const { id } = await this.$refs.modalBrowser.show('images')
      const data = {
        imageId: id,
        text: 'Slide: ' + newOrder,
        order: newOrder,
      }
      const res = await this.$axios.post('komsol/carousels', data)
      await this.getItems()
    },
    async editImage(item) {
      this.display = true
      const { id } = await this.$refs.modalBrowser.show('images')
      console.log(id)
      await this.$axios.put('komsol/carousels/' + item.id, {
        imageId: id,
      })
      await this.getItems()
    },
    async editText(item) {
      const { value: text } = await Swal.fire({
        title: 'Edit text',
        input: 'text',
        inputValue: item.text,
      })

      if (text) {
        await this.$axios.put('komsol/carousels/' + item.id, {
          text: text,
        })
        await this.getItems()
      }
    },
    async onDelete(item) {
      const result = await Swal.fire({
        title: 'Do you want to delete this slide?',
        icon: 'error',
        showCancelButton: true,
      })
      if (!result.isConfirmed) {
        return
      }
      await this.$axios.delete('komsol/carousels/' + item.id)
      await this.getItems()
    },
  },
}
</script>

<style>
.orderlist-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
}
.orderlist-item img {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-right: 1rem;
}
.orderlist-item .item-detail {
  flex: 1 1 0;
}

.orderlist-item .item-action {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
</style>
