<template>
  <div>
    <div class="row">
      <div class="col-3">
        <Toolbar v-if="!this.hideDropdown && !this.isSingleMode">
          <template #start>
            <Dropdown
              style="width: 150px"
              v-model="selectedFileType"
              :options="['images', 'files']"
              @change="fileTypeChanged"
            ></Dropdown>
          </template>

          <template #end>
            <Button
              label="New folder"
              icon="pi pi-plus-circle"
              class="p-button-sm p-button-success"
              @click="createFolder"
            />
          </template>
        </Toolbar>
        <Breadcrumb
          style="font-size: 12px; overflow-x: auto"
          :home="{ icon: 'pi pi-server' }"
          :model="breadcrumbItems"
        />

        <Tree
          :value="folders"
          selectionMode="single"
          scrollHeight="600px"
          @node-select="onNodeSelect"
        />
      </div>

      <div class="col-9">
        <div class="row">
          <Toolbar>
            <template #start>
              <InputText
                type="text"
                v-model="searchString"
                placeholder="Search"
                style="margin-right: 15px"
              />
              <input
                ref="pendingFiles"
                v-on:change="uploadFiles()"
                type="file"
                class="btn btn-outline-success"
                multiple
                hidden
              />
              <span class="p-buttonset">
                <Button
                  v-if="!this.hideUpload"
                  label="Upload"
                  icon="pi pi-upload"
                  class="p-button-sm p-button-success"
                  @click="$refs.pendingFiles.click()" />
                <Button
                  v-if="!this.hideDownload"
                  label="Download"
                  class="p-button-sm"
                  icon="pi pi-download"
                  @click="downloadSelected"
              /></span>
            </template>
            <template #end>
              <Button
                v-if="!this.hideDelete"
                label="Delete"
                icon="pi pi-times"
                class="p-button-sm p-button-danger"
              ></Button>
            </template>
          </Toolbar>
        </div>
        <div class="row">
          <!-- File browser -->
          <div id="fileContainer" class="col-md-9 overflow-auto">
            <div class="row" style="padding-left: 4px; margin-top: 4px">
              <template v-if="this.isImage">
                <div
                  v-for="(item, index) in files"
                  :key="index"
                  class="col-md-3"
                >
                  <figure class="figure" @click="selectFile(item)">
                    <img
                      :src="$helper.imgSrc(item.id, true)"
                      :class="[
                        'figure-img img-thumbnail',
                        { 'selected-thumbnail': this.selectedFiles.has(item) },
                      ]"
                    />
                    <figcaption class="figure-caption">
                      {{ item.filename }}
                    </figcaption>
                  </figure>
                </div>
              </template>
              <template v-else>
                <div
                  v-for="(item, index) in files"
                  :key="index"
                  @click="selectFile(item)"
                  :class="[
                    'col-md-3 border border-2 p-2',
                    { 'selected-thumbnail': this.selectedFiles.has(item) },
                  ]"
                >
                  <font-awesome-icon
                    icon="file-pdf"
                    size="2x"
                    data-toggle="tooltip"
                    title="Edit"
                  />
                  <figcaption class="figure-caption">
                    {{ item.filename }}
                  </figcaption>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'

export default {
  name: 'AppFileBrowser',
  emits: ['onFileSelected', 'onFileDeselected', 'onFolderChange'],
  props: {
    hideUpload: Boolean,
    hideDownload: Boolean,
    hideDelete: Boolean,
    hideDropdown: Boolean,
    onlyImage: Boolean,
    onlyFile: Boolean,
  },
  data() {
    return {
      folders: null,
      files: null,
      selectedFileType: this.getFileTypeOptionFromProp(),
      selectedFolder: null,
      selectedFiles: new Set(),
      searchString: null,
      searchTimer: null,
    }
  },
  computed: {
    breadcrumbItems() {
      const items =
        this.selectedFolder?.split('/').map((x) => {
          return { label: x }
        }) ?? []

      return items
    },
    isSingleMode() {
      return this.onlyImage || this.onlyFile
    },
    isImage() {
      return this.selectedFileType === 'images'
    },
  },
  async mounted() {
    await this.getFolders()
  },
  methods: {
    async refresh() {
      await this.getFolders()
      await this.getFiles()
    },

    async getFolders() {
      const query = { image: this.isImage }
      const res = await this.$axios.get('filebrowser/folders', {
        params: query,
      })
      this.folders = convertToTreeData(res.data)
    },
    async getFiles() {
      this.selectedFiles.clear()
      const query = {
        image: this.isImage,
        search: this.searchString,
        folder: this.selectedFolder,
      }
      const res = await this.$axios.get('filebrowser', {
        params: query,
      })
      this.files = res.data
    },
    async downloadSelected() {
      if (this.selectedFiles.size === 0) {
        return
      }

      const array = Array.from(this.selectedFiles)
      if (this.selectedFiles.size === 1) {
        const item = array[0]
        await this.downloadOne(item)
      } else {
        const filenames = array.map((x) => x.absoluteFilename)
        await this.downloadMany(filenames)
      }
    },
    async downloadOne(item) {
      const res = await this.$axios.get('filebrowser/download/' + item.id, {
        responseType: 'blob',
      })
      this.$helper.download(
        item.filename,
        res.data,
        res.headers['content-type']
      )
    },
    async downloadMany(filenames) {
      const res = await this.$axios.post('filebrowser/download/', filenames, {
        responseType: 'blob',
      })
      const zipName = 'collection-' + Date.now() + '.zip'
      this.$helper.download(zipName, res.data, res.headers['content-type'])
    },
    async uploadFiles() {
      const files = this.$refs.pendingFiles.files
      for (let i = 0; i < files.length; i++) {
        const formData = new FormData()
        formData.append('file', files[i])
        await this.$axios.post(this.selectedFileType, formData, {
          params: { folder: this.selectedFolder },
        })
      }
      await this.getFiles()
    },
    async createFolder() {
      const { value: text } = await Swal.fire({
        title: 'Create folder',
        input: 'text',
        inputValue: '',
      })

      if (text) {
        //const folder = findFolderByFullPath(this.folders, this.selectedFolder)
        const res = await this.$axios.post('filebrowser/', {
          folder: text,
          path: this.selectedFolder ?? '',
          isImage: this.isImage,
        })
        await this.refresh()
      }
    },
    async fileTypeChanged() {},
    async onNodeSelect(node) {
      this.selectedFolder = node.data
      await this.getFiles()
      this.$emit('onFolderChange', this.selectedFolder)
    },
    selectFile(item) {
      if (this.selectedFiles.has(item)) {
        this.selectedFiles.delete(item)
        this.$emit('onFileDeselected', item)
      } else {
        this.selectedFiles.add(item)
        this.$emit('onFileSelected', item)
      }
    },
    getFileTypeOptionFromProp() {
      if (this.onlyFile) return 'files'
      else return 'images'
    },
  },
  watch: {
    async selectedFileType(value) {
      this.files = []
      this.selectedFiles.clear()
      await this.getFolders()
    },
    searchString(value) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer)
      }
      this.searchTimer = setTimeout(async () => {
        await this.getFiles()
      }, 200)
    },
  },
}

const convertToTreeData = (folders) => {
  return folders.map((x) => {
    return {
      label: x.label,
      key: x.key,
      data: x.data,
      icon: 'pi pi-fw pi-folder',
      children: convertToTreeData(x.children),
    }
  })
}

const findFolderByFullPath = (folders, path) => {
  if (!folders) return null

  for (const folder of folders) {
    if (folder.data === path) {
      return folder
    }
    const obj = findFolderByFullPath(folder.children, path)
    if (obj) return obj
  }
}
</script>

<style>
#fileContainer {
  margin-top: 20px;
  max-height: 600px;
}

#fileContainer img {
  max-width: 128px;
  max-height: 128px;
}

#fileContainer figure:hover {
  opacity: 0.7;
}
#fileContainer .border:hover {
  border-color: darkgray !important;
}

.selected-thumbnail {
  border: solid 1px rgba(0, 140, 186, 0.5) !important;
  box-shadow: 0 0 4px 1px rgba(0, 140, 186, 0.5) !important;
  opacity: 0.5;
}
</style>
